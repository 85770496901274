import { ParsedUrlQuery } from 'querystring';

import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  filterMarkets,
  getCanonicalUrl,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { datoApi, getLocaleFromDato, markets } from '@hultafors/hultafors/api';
import {
  ArticlePageFragment,
  GlobalFields,
  PageProps,
} from '@hultafors/hultafors/types';

import { prodDomains } from '../../next.domains';

const ContentPage = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.ContentPage),
);
const Page = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.Page),
);
export interface ArticlePageProps extends PageProps {
  content: ArticlePageFragment;
}

interface ArticlePageParams extends ParsedUrlQuery {
  slug: string;
}

interface ArticlePagePath {
  params: ArticlePageParams;
  locale?: string;
}

export const getStaticPaths: GetStaticPaths<ArticlePageParams> = async ({
  defaultLocale,
}) => {
  const dato = datoApi(defaultLocale);
  const {
    data: { allArticlePages },
  } = await dato.getStaticPathsAllArticlePages();

  const paths: ArticlePagePath[] =
    allArticlePages.flatMap(({ slug, _locales }) => {
      return _locales.filter(Boolean).map((datoLocale) => {
        const locale = getLocaleFromDato(datoLocale);
        return {
          locale,
          params: { slug },
        };
      });
    }) ?? [];
  return {
    fallback: 'blocking',
    paths,
  };
};

export const getStaticProps: GetStaticProps<
  ArticlePageProps,
  ArticlePageParams
> = async ({ params: { slug }, locale }) => {
  const revalidate = 60; // 1 minute
  try {
    const dato = datoApi(locale);

    const path = `/articles/${slug}`;
    const canonical = getCanonicalUrl(
      locale,
      prodDomains,
      'www.hultafors.com',
      path,
    );

    const {
      data: { articlePage: content, ...globalFields },
    } = await dato.getArticlePage(slug);
    if (!content || content === null) {
      return {
        notFound: true,
      };
    }

    const hrefLanguages = getHrefLanguages(
      null,
      filterMarkets(markets, 'HULTAFORS'),
      content?.pageLocales,
    );

    return {
      props: {
        content,
        ...(globalFields as GlobalFields),
        canonical,
        hrefLanguages,
      },
      revalidate,
    };
  } catch (error) {
    console.error(error);
    return {
      notFound: true,
      revalidate,
    };
  }
};

const ArticlePage: NextPage<ArticlePageProps> = ({
  canonical,
  content,
  hrefLanguages,
}) => {
  return (
    <Page seo={content.seo} canonical={canonical} hrefLanguages={hrefLanguages}>
      <ContentPage pageContent={content} />
    </Page>
  );
};

export default ArticlePage;
